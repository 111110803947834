import React, { useEffect, useState } from 'react';
import { Form, Input, FormItemProps, Select } from 'antd';
import { useIntl } from 'react-intl';
import { TongyongZhankaiXia } from '@sirius/icons';
import { countriesGet } from '@api/system-global/__generate__/default/LocationService';
import styles from './style.module.scss';

interface IComProps extends FormItemProps {
  disabled?: boolean;
  onPressEnter?: () => void;
}

interface IPhoneFieldType {
  phoneNumber: string;
}

interface ICodeFieldType {
  phoneCountryCode: string;
}

interface CodeOption {
  label: string;
  value: string;
}

const TelephoneInputItem: React.FC<IComProps> = ({ disabled, onPressEnter }) => {
  const intl = useIntl();
  const [opations, setOpations] = useState<Array<CodeOption>>([]);

  useEffect(() => {
    // 获取国家信息
    countriesGet().then(res => {
      const temp: Record<string, boolean> = {};
      const data = res?.data?.data?.filter(item => {
        if (temp[item.callingCode]) {
          return false;
        }
        temp[item.callingCode] = true;
        return true;
      });
      setOpations(data?.map(item => ({ label: item.name, value: item.callingCode })) || []);
    });
  }, []);

  const baseLabel = intl.formatMessage({ id: '电话号码' }).toUpperCase();

  return (
    <Form.Item label={baseLabel} className={styles.wrapper}>
      <Form.Item<ICodeFieldType> name="phoneCountryCode" noStyle required={false}>
        <Select
          style={{ width: 130 }}
          suffixIcon={<TongyongZhankaiXia style={{ width: 22, height: 22, color: '#8D92A1' }} />}
          size="large"
          variant="borderless"
          options={opations}
          showSearch
          dropdownStyle={{ width: 362 }}
          filterOption={(input: string, option) => {
            const _option = option as CodeOption;
            const _input = input.toLowerCase();
            return _option.label?.toLowerCase().includes(_input) || _option.value?.toLowerCase().includes(_input);
          }}
          optionRender={option => {
            const data = option.data as CodeOption;
            return (
              <div className={styles.fieldOption}>
                <div className={styles.fieldLabel}>{data.label}</div>
                <div className={styles.fieldDesc}>{data.value}</div>
              </div>
            );
          }}
          labelRender={item => {
            return item.value;
          }}
        />
      </Form.Item>
      <div className={styles.divide}></div>
      <Form.Item<IPhoneFieldType>
        name="phoneNumber"
        noStyle
        required={false}
        rules={[
          {
            pattern: /^\(?\d{1,4}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/,
            message: intl.formatMessage({ id: '电话号码错误' }),
          },
        ]}
      >
        <Input disabled={disabled} onPressEnter={onPressEnter} size="large" variant="borderless" allowClear />
      </Form.Item>
    </Form.Item>
  );
};

export default TelephoneInputItem;
