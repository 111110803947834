import React from 'react';
import { Form, Input, FormItemProps } from 'antd';
import { useIntl } from 'react-intl';
import { TongyongGuanbiXian } from '@sirius/icons';

interface IComProps extends FormItemProps {
  disabled?: boolean;
  onPressEnter?: () => void;
}

interface IFieldType {
  appsumoCode: string;
}

const AppSumoCodeItem: React.FC<IComProps> = ({ disabled, onPressEnter }) => {
  const intl = useIntl();
  const baseLabel = intl.formatMessage({ id: 'AppSumo码' }).toUpperCase();

  return (
    <Form.Item<IFieldType>
      name="appsumoCode"
      label={baseLabel}
      required={false}
      rules={[
        // {
        //   required: true,
        //   message: intl.formatMessage({ id: 'AppSumo码提示' }),
        // },
        {
          pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{0,32}$/,
          message: intl.formatMessage({ id: 'AppSumo码错误提示' }),
        },
      ]}
    >
      <Input
        size="large"
        variant="filled"
        disabled={disabled}
        onPressEnter={onPressEnter}
        // placeholder={intl.formatMessage({ id: 'AppSumo码提示' })}
        allowClear={{ clearIcon: <TongyongGuanbiXian style={{ fontSize: 16 }} /> }}
      />
    </Form.Item>
  );
};

export default AppSumoCodeItem;
