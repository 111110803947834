import { intl } from '@system-global/lang';
import { validateEmail, validPasswordContent, validPasswordLength } from '@utils/common';
import { emailSignUpCheckPost } from '@api/system-global/__generate__/default/UserSignupService';
import { RegisterStatus } from '@system-global/config/user';

// export enum RegisterStatus {
//   unregister = 'UN_SIGN_UP', // 未注册
//   toVerify = 'ALREADY_SIGN_UP', // 已注册未验证邮件
//   verified = 'ALREADY_VERIFIED', // 已验证邮件（正式用户）
// }

export const onValidateEmail = (
  inputEmail: string | undefined,
  { scene, checkType }: { scene: 'register' | 'resetPwd' | 'login'; checkType?: string[] }
): Promise<void> => {
  const checkEmailLength = !checkType || checkType.includes('length');
  const checkEmailFormat = !checkType || checkType.includes('format');
  const checkEmailOnServer = !checkType || checkType.includes('server');
  const email = inputEmail ? inputEmail.trim() : '';
  if (checkEmailLength && !email) {
    return Promise.reject({
      content: intl.formatMessage({ id: '字段不能为空' }, { field: intl.formatMessage({ id: '邮箱' }) }),
    });
  }
  if (checkEmailFormat && !validateEmail(email)) {
    return Promise.reject({
      content: intl.formatMessage({ id: '请输入正确的邮箱' }),
    });
  }
  return checkEmailOnServer
    ? emailSignUpCheckPost({ email }, { noErrorHint: true }).then(({ data }) => {
        const status = data.data.emailSignUpCheckStatus;
        if (scene === 'register') {
          if (status === RegisterStatus.toVerify) {
            return Promise.reject({
              status: RegisterStatus.toVerify,
              content: intl.formatMessage({ id: '邮箱待验证' }),
            });
          }
          if (status === RegisterStatus.verified) {
            return Promise.reject({
              status: RegisterStatus.verified,
              content: intl.formatMessage({ id: '邮箱已注册' }),
            });
          }
        } else {
          if (status === RegisterStatus.toVerify) {
            return Promise.reject({
              status: RegisterStatus.toVerify,
              content: intl.formatMessage({ id: '邮箱待验证' }),
            });
          }
          if (status === RegisterStatus.unregister) {
            return Promise.reject({
              status: RegisterStatus.unregister,
              content: intl.formatMessage({ id: '邮箱未注册' }),
            });
          }
        }
        return Promise.resolve();
      })
    : Promise.resolve();
};

export const onValidatePwd = (password: string): Promise<void> => {
  if (!password) {
    return Promise.reject(intl.formatMessage({ id: '字段不能为空' }, { field: intl.formatMessage({ id: '密码' }) }));
  }
  if (!validPasswordLength(password)) {
    return Promise.reject(intl.formatMessage({ id: '密码8-20个字符' }));
  }
  if (!validPasswordContent(password)) {
    return Promise.reject(intl.formatMessage({ id: '密码至少包含一个字母和一个数字' }));
  }
  return Promise.resolve();
};

export const onValidatePwdRepeat = (pwd: string, lastPwd: string): Promise<void> => {
  if (!pwd) {
    return Promise.reject(intl.formatMessage({ id: '再次输入密码' }));
  }
  if (pwd !== lastPwd) {
    return Promise.reject(intl.formatMessage({ id: '两次密码不一致' }));
  }
  return Promise.resolve();
};

export const onValidBlank = (input: string, message: string, trim = true) => {
  const trimInput = trim ? input?.trim() : input;
  if (!trimInput) {
    return Promise.reject(message);
  }
  return Promise.resolve();
};
