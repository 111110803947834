import { useState } from 'react';
import styles from './styles.module.scss';
import LoginContainer from '@system-global/components/login-and-register-form/login-container';
import { emailSignUpPost } from '@api/system-global/__generate__/default/UserSignupService';
import { IApiEmailSignupReq } from '@api/system-global/__generate__/default/models';
import EmailAndPwdForm, {
  IEmailAndPwdFieldType,
} from '@system-global/pages/special-register/appsumo/components/email-and-pwd-form';
import AccountForm, { IAccountFieldType } from '@system-global/pages/special-register/appsumo/components/account-form';
import { useKeepNavigate } from '@system-global/hooks/use-keep-navigate';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { useStore } from '@system-global/store';

type IFormType = 'emailAndPwd' | 'account';

const RegisterPage = () => {
  const nav = useKeepNavigate();

  const [loading, setLoading] = useState(false);
  const [formType, setFormType] = useState<IFormType>('emailAndPwd');
  const saveRegisterInfo = useStore(state => state.login_setRegisterInfo);

  const [registerInfo, setRegisterInfo] = useState<IApiEmailSignupReq>({
    companyName: '',
    email: '',
    firstName: '',
    lastName: '',
    pwd: '',
    website: '',
    invitationCode: '',
  });

  const onEmailAndPwdSubmit = ({ email, pwd, invitationCode }: IEmailAndPwdFieldType) => {
    setRegisterInfo(v => ({
      ...v,
      email,
      pwd,
      invitationCode,
    }));
    setFormType('account');
  };

  const onAccountSubmit = ({ companyName, firstName, lastName, website }: IAccountFieldType) => {
    const req: IApiEmailSignupReq = {
      ...registerInfo,
      companyName,
      firstName,
      lastName,
      website,
    };
    setRegisterInfo(req);
    setLoading(true);
    emailSignUpPost(req)
      .then(res => {
        if (res?.data?.success) {
          saveRegisterInfo({ email: req.email, sendTime: Date.now() });
          setTimeout(() => {
            nav(ROUTE_URLS.verifyEmail);
          }, 500);
        }
      })
      .catch(e => {
        console.error(e, 'emailSignUpPost error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.registerPage}>
      <LoginContainer>
        <div className={styles.content} id="registerPageContent">
          {formType === 'emailAndPwd' && <EmailAndPwdForm registerInfo={registerInfo} onSubmit={onEmailAndPwdSubmit} />}
          {formType === 'account' && (
            <AccountForm
              registerInfo={registerInfo}
              loading={loading}
              onSubmit={onAccountSubmit}
              onGoBack={() => setFormType('emailAndPwd')}
            />
          )}
        </div>
      </LoginContainer>
    </div>
  );
};

export default RegisterPage;
