/** 用户通用接口（鉴权） */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 用户通用接口（鉴权） 修改密码 */
export async function changePwdPost(payload: model.IApiChangePwdReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/user/change/pwd`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 用户通用接口（鉴权） 访客识别-用户额度数量信息 */
export async function identifyQuotaCountInfoGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultQuotaCountInfo>({
    url: `/api/biz/user/identifyQuotaCountInfo`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 用户通用接口（鉴权） 登出 */
export async function logoutPost(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/biz/user/logout`,
    method: 'post',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 用户通用接口（鉴权） 额度不足/过期时汇总数据 */
export async function reachLimitStatisticsGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultReachLimitStatisticResp>({
    url: `/api/biz/user/reachQuotaLimitStatistics`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 用户通用接口（鉴权） 修改用户信息 */
export async function updateUserInfoPost(payload: model.IApiUpdateUserInfoReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/user/update`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 用户通用接口（鉴权） 更新用户邀请码 */
export async function updateUserInvitationPost(
  payload: model.IApiUpdateUserInvitationCodeReq,
  customParams?: IAxiosCustomParams
) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultReachLimitStatisticResp>({
    url: `/api/biz/user/updateUserInvitation`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 用户通用接口（鉴权） 获取用户信息 */
export async function userInfoGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultUserInfoResp>({
    url: `/api/biz/user/userInfo`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
