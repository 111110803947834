const ROUTE_URL_LEVEL1 = {
  root: '/',
  visitors: '/visitors',
  sign: '/sign',
  settings: '/settings',
  installTracker: '/install-tracker',
  purchase: '/purchase',
  blank: '/blank',
  oauth: '/oauth',
  handle: '/handle',
};

const ROUTE_URL_LEVEL2 = {
  basic: '/basic',
  history: '/history',
  setting: '/personal-setting',
  purchaseSuccess: '/success',
  purchaseError: '/error',
  purchaseResult: '/result',
  billing: '/billing',
  company: '/company',
  google: '/google',
  facebook: '/facebook',
  integration: '/integration',
  appsumo: '/appsumo',
  register: '/register',
};

const ROUTE_URL_LEVEL3 = {
  allVisitors: '/all',
  newVisitors: '/new',
  visitorHistory: '/contacts',
  personalSetting: '/personal',
  login: '/sign-in',
  register: '/sign-up',
  resetPwd: '/reset-pwd',
  verifyEmail: '/verify-email',
  subscriptions: '/subscriptions',
  tracker: '/tracker',
  emailNotifications: '/emial-notifications',
  oauthHandle: '/handle',
  hubspot: '/hubspot',
  quickLogin: '/quick-login',
  success: '/success',
};

/*
 * 大部分的叶子路由三级构成
 * 例如 /visitors/basic/new，第一级 /visitors 是用来确定 layout，第二级 /basic 用来确定菜单的父级菜单（可折叠收起部分），第三级是用来确定页面本身
 * 如果是登录页面，会省略第二级（确定菜单的那一级）
 */
export const ROUTE_URLS = {
  default: `${ROUTE_URL_LEVEL1.visitors}${ROUTE_URL_LEVEL2.basic}${ROUTE_URL_LEVEL3.allVisitors}`,

  // 一级路由
  ...ROUTE_URL_LEVEL1,

  // 二级路由
  ...ROUTE_URL_LEVEL2,

  // 叶子路由
  allVisitors: `${ROUTE_URL_LEVEL1.visitors}${ROUTE_URL_LEVEL2.basic}${ROUTE_URL_LEVEL3.allVisitors}`,
  newVisitors: `${ROUTE_URL_LEVEL1.visitors}${ROUTE_URL_LEVEL2.basic}${ROUTE_URL_LEVEL3.newVisitors}`,
  visitorHistory: `${ROUTE_URL_LEVEL1.visitors}${ROUTE_URL_LEVEL2.history}${ROUTE_URL_LEVEL3.visitorHistory}`,
  personalSetting: `${ROUTE_URL_LEVEL1.settings}${ROUTE_URL_LEVEL2.setting}${ROUTE_URL_LEVEL3.personalSetting}`,
  emailNotifications: `${ROUTE_URL_LEVEL1.settings}${ROUTE_URL_LEVEL2.setting}${ROUTE_URL_LEVEL3.emailNotifications}`,
  login: `${ROUTE_URL_LEVEL1.sign}${ROUTE_URL_LEVEL3.login}`,
  register: `${ROUTE_URL_LEVEL1.sign}${ROUTE_URL_LEVEL3.register}`,
  registerAppsumo: `${ROUTE_URL_LEVEL1.sign}${ROUTE_URL_LEVEL3.register}/appsumo`,
  resetPwd: `${ROUTE_URL_LEVEL1.sign}${ROUTE_URL_LEVEL3.resetPwd}`,
  verifyEmail: `${ROUTE_URL_LEVEL1.sign}${ROUTE_URL_LEVEL3.verifyEmail}`,
  purchase: `${ROUTE_URL_LEVEL1.purchase}`,
  purchaseSuccess: `${ROUTE_URL_LEVEL1.purchase}${ROUTE_URL_LEVEL2.purchaseSuccess}`,
  purchaseError: `${ROUTE_URL_LEVEL1.purchase}${ROUTE_URL_LEVEL2.purchaseError}`,
  purchaseResult: `${ROUTE_URL_LEVEL1.purchase}${ROUTE_URL_LEVEL2.purchaseResult}/:orderId`,
  blankPage: `${ROUTE_URL_LEVEL1.blank}`,
  billing: `${ROUTE_URL_LEVEL1.settings}${ROUTE_URL_LEVEL2.billing}${ROUTE_URL_LEVEL3.subscriptions}`,
  companyTracker: `${ROUTE_URL_LEVEL1.settings}${ROUTE_URL_LEVEL2.company}${ROUTE_URL_LEVEL3.tracker}`,
  subscriptions: `${ROUTE_URL_LEVEL1.settings}${ROUTE_URL_LEVEL2.billing}${ROUTE_URL_LEVEL3.subscriptions}`,
  googleOAuthHandle: `${ROUTE_URL_LEVEL1.oauth}${ROUTE_URL_LEVEL2.google}${ROUTE_URL_LEVEL3.oauthHandle}`,
  facebookOAuthHandle: `${ROUTE_URL_LEVEL1.oauth}${ROUTE_URL_LEVEL2.facebook}${ROUTE_URL_LEVEL3.oauthHandle}`,
  hubspotIntergration: `${ROUTE_URL_LEVEL1.settings}${ROUTE_URL_LEVEL2.integration}${ROUTE_URL_LEVEL3.hubspot}`,
  quickLogin: `${ROUTE_URL_LEVEL1.sign}${ROUTE_URL_LEVEL3.quickLogin}`,
  handleAppsumo: `${ROUTE_URL_LEVEL1.handle}${ROUTE_URL_LEVEL2.appsumo}`,
  registerSuccess: `${ROUTE_URL_LEVEL1.sign}${ROUTE_URL_LEVEL3.register}${ROUTE_URL_LEVEL3.success}`,
};
