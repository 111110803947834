import React from 'react';
import { Button, Flex } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { TongyongShangchuan2Mian } from '@sirius/icons';
import styles from './index.module.scss';

export type UpgradeReminderStatus = 'expired' | 'usedUp';

export interface UpgradeMiniTipUiProps {
  status?: UpgradeReminderStatus;
  days?: number;
}
export interface UpgradeMiniTipProps {
  /**
   * 免费体验剩余天数
   */
  trialDaysLeft: number | undefined;
}
export const UpgradeMiniTipUi: React.FC<UpgradeMiniTipUiProps> = props => {
  // const { status, days } = props;
  const navigate = useNavigate();
  const location = useLocation();
  // const isUsedUp = status === 'usedUp';

  return (
    <Flex align="center" gap={8} className={styles['mini-container']}>
      {/* <div className={isUsedUp ? styles['mini-tip-used-up'] : styles['mini-tip-expired']}>
        {status === 'expired' ? (
          <FormattedMessage id="体验期已结束" />
        ) : (
          <FormattedMessage
            id="还可以免费试用#天"
            values={{
              days: days,
            }}
          />
        )}
      </div> */}
      <Button
        className={styles['btn']}
        icon={<TongyongShangchuan2Mian />}
        onClick={() => {
          if (location.pathname === '/purchase') {
            return;
          }
          navigate('/purchase');
        }}
      >
        <FormattedMessage id="升级套餐" />
      </Button>
    </Flex>
  );
};

export const UpgradeMiniTip: React.FC<UpgradeMiniTipProps> = props => {
  const { trialDaysLeft } = props;
  // if (trialDaysLeft === undefined) {
  //   return null;
  // }
  return <UpgradeMiniTipUi days={trialDaysLeft} />;
};
