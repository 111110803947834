import React, { lazy } from 'react';
import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';
import { IRouteItem } from './types';
import NotFoundPage from '@ui/components/404';
import { LoginStatus } from '@system-global/config/user';
import { ROUTE_URLS } from './route-urls';

import AppRoot from '@system-global/layout/app-root';
import ContentRoot from '@system-global/layout/sub-layouts/content-root';
import SettingRoot from '@system-global/layout/sub-layouts/setting-root';
import BlankRoot from '@system-global/layout/sub-layouts/blank-root';
import LoginRoot from '@system-global/layout/sub-layouts/login-root';
import PaymentRoot from '@system-global/layout/sub-layouts/payment-root';
import GoogleAuthHandle from '@system-global/pages/oauth/google/handle';
import FacebookAuthHandle from '@system-global/pages/oauth/facebook/handle';
import ErrorPage from '@ui/components/error-page';
import LoginPage from '@system-global/pages/login';
import RegisterPage from '@system-global/pages/register';
import RegisterPageAppsumo from '@system-global/pages/special-register/appsumo';
import ResetPwdPage from '@system-global/pages/reset-password';
import VerifyEmailPage from '@system-global/pages/verify-email';
import QuickLoginPage from '@system-global/pages/quick-login';
import MobileInterceptPage from '@system-global/mobile/pages/intercept';

// const LoginPage = lazy(() => import('@system-global/pages/login'));
const BlankPage = lazy(() => import('@system-global/pages/blank'));
const AllVisitorListPage = lazy(() => import('@system-global/pages/all-visitor-list'));
const NewVisitorListPage = lazy(() => import('@system-global/pages/new-visitor-list'));
const InstallTrackerPage = lazy(() => import('@system-global/pages/install-tracker'));
const ContactHistory = lazy(() => import('@system-global/pages/contact-history'));
const PersonalSettingPage = lazy(() => import('@system-global/pages/personal-setting'));
const EmailNotifications = lazy(() => import('@system-global/pages/email-notifications'));
const Purchase = lazy(() => import('@system-global/pages/purchase'));
const PurchaseSuccess = lazy(() => import('@system-global/pages/purchase-state/success'));
const PurchaseError = lazy(() => import('@system-global/pages/purchase-state/error'));
const PurchaseResult = lazy(() => import('@system-global/pages/purchase-state/result'));
const Tracker = lazy(() => import('@system-global/pages/tracker'));
// const GoogleAuthHandle = lazy(() => import('@system-global/pages/oauth/google/handle'));
const Subscriptions = lazy(() => import('@system-global/pages/subscriptions-setting'));
const HubSpotInergration = lazy(() => import('@system-global/pages/integration/hubspot'));
// const MobileInterceptPage = lazy(() => import('@system-global/mobile/pages/intercept'));
const ReisterSuccess = lazy(() => import('@system-global/pages/register-success'));
const AppsumoHandle = lazy(() => import('@system-global/pages/handle/appsumo'));
const DevPage = lazy(() => import('@system-global/pages/dev-page'));

export const routes: IRouteItem[] = [
  {
    path: ROUTE_URLS.root,
    element: <AppRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ROUTE_URLS.root,
        element: <BlankRoot />,
        children: [
          {
            path: ROUTE_URLS.installTracker,
            element: <InstallTrackerPage />,
          },
          {
            path: ROUTE_URLS.purchaseSuccess,
            element: <PurchaseSuccess />,
            config: {
              loginStatus: LoginStatus.loginOptional,
            },
          },
          {
            path: ROUTE_URLS.blankPage,
            element: <BlankPage />,
            config: {
              loginStatus: LoginStatus.loginOptional,
            },
          },
          {
            path: ROUTE_URLS.googleOAuthHandle,
            element: <GoogleAuthHandle />,
            config: {
              loginStatus: LoginStatus.loginOptional,
            },
          },
          {
            path: ROUTE_URLS.facebookOAuthHandle,
            element: <FacebookAuthHandle />,
            config: {
              loginStatus: LoginStatus.loginOptional,
            },
          },
          {
            path: ROUTE_URLS.registerSuccess,
            element: <ReisterSuccess />,
            config: {
              loginStatus: LoginStatus.loginOptional,
            },
          },
          {
            path: ROUTE_URLS.handleAppsumo,
            element: <AppsumoHandle />,
            config: {
              loginStatus: LoginStatus.loginOptional,
            },
          },
        ],
      },
      {
        path: ROUTE_URLS.sign,
        element: <LoginRoot />,
        config: {
          loginStatus: LoginStatus.withLogout,
        },
        children: [
          {
            path: ROUTE_URLS.login,
            element: <LoginPage />,
            config: {
              loginStatus: LoginStatus.withLogout,
            },
          },
          {
            path: ROUTE_URLS.register,
            element: <RegisterPage />,
            config: {
              loginStatus: LoginStatus.withLogout,
            },
          },
          {
            path: ROUTE_URLS.registerAppsumo,
            element: <RegisterPageAppsumo />,
            config: {
              loginStatus: LoginStatus.withLogout,
            },
          },
          {
            path: ROUTE_URLS.resetPwd,
            element: <ResetPwdPage />,
            config: {
              loginStatus: LoginStatus.withLogout,
            },
          },
          {
            path: ROUTE_URLS.verifyEmail,
            element: <VerifyEmailPage />,
            config: {
              loginStatus: LoginStatus.withLogout,
            },
          },
          {
            path: ROUTE_URLS.quickLogin,
            element: <QuickLoginPage />,
            config: {
              loginStatus: LoginStatus.loginOptional,
            },
          },
        ],
      },
      {
        path: ROUTE_URLS.visitors,
        element: <ContentRoot />,
        children: [
          {
            path: '',
            element: <Navigate to={ROUTE_URLS.allVisitors} replace />,
          },
          {
            path: ROUTE_URLS.allVisitors,
            element: <AllVisitorListPage />,
          },
          {
            path: ROUTE_URLS.newVisitors,
            element: <NewVisitorListPage />,
          },
          {
            path: ROUTE_URLS.visitorHistory,
            element: <ContactHistory />,
          },
        ],
      },
      {
        path: ROUTE_URLS.purchase,
        element: <PaymentRoot />,
        children: [
          {
            path: '',
            element: <Purchase />,
          },
          {
            path: ROUTE_URLS.purchaseResult,
            element: <PurchaseResult />,
          },
          {
            path: ROUTE_URLS.purchaseError,
            element: <PurchaseError />,
          },
        ],
      },
      {
        path: ROUTE_URLS.settings,
        element: <SettingRoot />,
        children: [
          {
            path: '',
            element: <Navigate to={ROUTE_URLS.personalSetting} replace />,
          },
          {
            path: ROUTE_URLS.personalSetting,
            element: <PersonalSettingPage />,
          },
          {
            path: ROUTE_URLS.subscriptions,
            element: <Subscriptions />,
          },
          {
            path: ROUTE_URLS.companyTracker,
            element: <Tracker />,
          },
          {
            path: ROUTE_URLS.emailNotifications,
            element: <EmailNotifications />,
          },
          {
            path: ROUTE_URLS.hubspotIntergration,
            element: <HubSpotInergration />,
          },
        ],
      },
      {
        path: 'devpage',
        element: <ContentRoot />,
        children: [
          {
            path: '',
            element: <DevPage />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];

const _renderRoutes = (routes: IRouteItem[]): RouteObject[] =>
  routes.map(route => {
    const fullPath = route.path;
    const children = route.children && route.children.length > 0 ? _renderRoutes(route.children) : undefined;
    return {
      path: fullPath,
      element: route.element,
      children,
      handle: route.config,
      errorElement: route.errorElement,
    };
  });

export const router = createBrowserRouter(_renderRoutes(routes));

export const mobileRoutesConfig: IRouteItem[] = [
  {
    path: ROUTE_URLS.root,
    element: <AppRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ROUTE_URLS.root,
        element: <BlankRoot />,
        children: [
          {
            path: ROUTE_URLS.blankPage,
            element: <BlankPage />,
            config: {
              loginStatus: LoginStatus.loginOptional,
            },
          },
          {
            path: ROUTE_URLS.googleOAuthHandle,
            element: <GoogleAuthHandle />,
            config: {
              loginStatus: LoginStatus.loginOptional,
            },
          },
        ],
      },
      {
        path: ROUTE_URLS.sign,
        element: <LoginRoot />,
        config: {
          loginStatus: LoginStatus.withLogout,
        },
        children: [
          {
            path: ROUTE_URLS.login,
            element: <LoginPage />,
            config: {
              loginStatus: LoginStatus.withLogout,
            },
          },
          {
            path: ROUTE_URLS.register,
            element: <RegisterPage />,
            config: {
              loginStatus: LoginStatus.withLogout,
            },
          },
          {
            path: ROUTE_URLS.resetPwd,
            element: <ResetPwdPage />,
            config: {
              loginStatus: LoginStatus.withLogout,
            },
          },
          {
            path: ROUTE_URLS.verifyEmail,
            element: <VerifyEmailPage />,
            config: {
              loginStatus: LoginStatus.withLogout,
            },
          },
          {
            path: ROUTE_URLS.quickLogin,
            element: <QuickLoginPage />,
            config: {
              loginStatus: LoginStatus.loginOptional,
            },
          },
        ],
      },
      {
        path: '*',
        element: <MobileInterceptPage />,
      },
    ],
  },
];

export const mobileRouter = createBrowserRouter(_renderRoutes(mobileRoutesConfig));
