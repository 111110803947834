/** 位置信息相关 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 位置信息相关 获取全部国家信息 */
export async function countriesGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultCollectionLocationCountryResp>({
    url: `/api/pub/location/countries`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 位置信息相关 获取当前所在国家 */
export async function countryGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultLocationCountryResp>({
    url: `/api/pub/location/country`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
