import { useState, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStore } from '@system-global/store';
import { getUserInfoPost } from '@api/system-global/__generate__/default/OAuthLoginService';
import { oAuthLoginPost } from '@api/system-global/__generate__/default/UserLoginService';
import { localRegisterInfoHandler } from '@system-global/utils/common';

export enum LoginState {
  Loading = 'Loading',
  Login = 'Login',
  Register = 'Register',
  StateError = 'StateError',
  NeedPasswordLogin = 'NeedPasswordLogin',
  NeedGoogleLogin = 'NeedGoogleLogin',
  NeedFacebookLogin = 'NeedFacebookLogin',
  NeedVerifyEmail = 'NeedVerifyEmail',
}

type OauthType = 'FACEBOOK' | 'GOOGLE';
export function useLoginState(oauthType: OauthType) {
  const [searchParams] = useSearchParams();
  // const authState = googleOauthStateHandler.get();
  const [state, setState] = useState<LoginState>(LoginState.Loading);
  const [email, setEmail] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const doLoginSucceed = useStore(state => state.login_doLoginSucceed);

  const getUserState = useCallback(async () => {
    const code = searchParams.get('code');
    const allState = searchParams.get('state');
    const [_, redirectUrl] = allState ? allState.split('@@@') : [];

    if (!code) {
      setState(LoginState.StateError);
      return;
    }

    const res = await getUserInfoPost({ code, oauthType });
    if (res?.data?.success === false) {
      setState(LoginState.StateError);
      return;
    }
    const userState = res?.data?.data || {};

    if (userState.oauthStatus === 2) {
      // 已注册 登录
      setState(LoginState.Loading);
      const res = await oAuthLoginPost({
        accessCode: userState.accessCode,
        oauthType,
        userIdentify: userState.userIdentify,
      });

      const token = res?.data?.data?.token;
      localRegisterInfoHandler.del();
      doLoginSucceed(token);
      setTimeout(() => {
        window.location.href = redirectUrl ? decodeURIComponent(redirectUrl) : '/';
      }, 500);
      return;
    }

    setEmail(userState.userIdentify || '');
    setAccessCode(userState.accessCode);

    if (userState.oauthStatus === 1) {
      // 未注册 预填邮箱去注册
      setState(LoginState.Register);
      return;
    }

    if (userState.oauthStatus === 3) {
      // 已存在其他方式注册， 目前只能用户名密码登录
      setState(LoginState.NeedPasswordLogin);
      return;
    }

    if (userState.oauthStatus === 4) {
      setState(LoginState.NeedGoogleLogin);
      return;
    }

    if (userState.oauthStatus === 5) {
      setState(LoginState.NeedFacebookLogin);
      return;
    }

    if (userState.oauthStatus === 6) {
      setState(LoginState.NeedVerifyEmail);
      return;
    }

    // 接口异常状态
    setState(LoginState.Loading);
  }, [searchParams, doLoginSucceed, oauthType]);

  useEffect(() => {
    getUserState();
  }, [getUserState]);

  return { state, email, accessCode };
}
