import { useCallback, useState, useMemo } from 'react';
import { Form } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useStore } from '@system-global/store';
import { oAuthSignUpPost } from '@api/system-global/__generate__/default/UserSignupService';
import { localRegisterInfoHandler } from '@system-global/utils/common';
import { useDefaultPhoneCode } from '@system-global/hooks/useDefaultPhoneCode';
import { ROUTE_URLS } from '@system-global/config/route-urls';

interface RegisterInfo {
  defaultEmail: string;
  accessCode: string;
}

export function useRegisterForm(regInfo: RegisterInfo) {
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const doLoginSucceed = useStore(state => state.login_doLoginSucceed);

  useDefaultPhoneCode(form);

  const initalValue = useMemo(() => {
    return {
      firstName: '',
      lastName: '',
      email: regInfo.defaultEmail,
    };
  }, [regInfo.defaultEmail]);

  const submit = useCallback(async () => {
    try {
      setLoading(true);
      const allState = searchParams.get('state');
      const [_, redirectUrl] = allState ? allState.split('@@@') : [];
      const emailRedirectExtra = decodeURIComponent(redirectUrl || '');
      const data = await form.validateFields();
      const res = await oAuthSignUpPost({
        accessCode: regInfo.accessCode,
        userIdentify: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        invitationCode: data.invitationCode,
        phoneCountryCode: data.phoneCountryCode,
        phoneNumber: data.phoneNumber,
        oauthType: 'GOOGLE',
        emailRedirectExtra,
      });

      if (!res?.data?.success) {
        return;
      }

      const token = res?.data?.data?.token;
      localRegisterInfoHandler.del();
      doLoginSucceed(token);
      const toUrl = `${ROUTE_URLS.registerSuccess}?fms=google${
        emailRedirectExtra ? `&redirectUrl=${encodeURIComponent(emailRedirectExtra)}` : ''
      }`;
      setTimeout(() => {
        window.location.href = toUrl;
      }, 500);
    } finally {
      setLoading(false);
    }
  }, [searchParams, form, regInfo.accessCode, doLoginSucceed]);

  return { form, submit, loading, initalValue };
}
