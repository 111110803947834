import { message } from 'antd';
import { IInterceptorReqHandler, IInterceptorResHandler } from './types';
import { StoreApi } from 'zustand/esm/vanilla';

// 网络错误提示, 同一时间只提示一次
const hintNetErrorFactory = () => {
  let messageBoxCount = 0;
  return (content: string) => {
    console.log('messageBoxCount', messageBoxCount);
    if (messageBoxCount === 0) {
      messageBoxCount += 1;
      message.error({
        content,
        duration: 1.5,
        onClose() {
          messageBoxCount -= 1;
        },
      });
    }
  };
};

const hintNetError = hintNetErrorFactory();

// 拦截器：拦截请求，添加 url 前缀
export const genUrlPrefixReqInterceptor = (params: { prefix: string }): IInterceptorReqHandler => ({
  onFulfilled: config => {
    if (params.prefix) {
      config.url = `${params.prefix}${config.url}`;
    }
    return config;
  },
  onRejected(err) {
    return Promise.reject(err);
  },
});

// 拦截器：拦截请求，添加 token 等信息
export const genTokenReqInterceptor = (params: {
  store: StoreApi<{ login_token: string }>;
}): IInterceptorReqHandler => ({
  // 正常请求向 header 中添加 token 等信息
  onFulfilled: config => {
    const _token = config?.customParams?.token || params.store.getState().login_token;
    if (_token) {
      config.headers.Authorization = `Bearer ${_token}`;
    }
    return config;
  },
  // 异常请求直接拒绝，由响应拦截器统一处理
  onRejected(err) {
    return Promise.reject(err);
  },
});

//  错误处理
export const genErrorResInterceptor = (params: {
  noErrorHint: boolean;
  onLogout?: (code: number) => void;
  errorTip?: string;
  accessErrorTip?: string;
  timeoutTip?: string;
}): IInterceptorResHandler => ({
  // 拦截业务异常响应
  onFulfilled: response => {
    const { data, config } = response;
    if (+data.code !== 0) {
      const errorInfo = data.message || params.errorTip;
      // 除非传入 noErrorHint 参数，否则都会进行错误提示
      const noErrorHint = config?.customParams?.noErrorHint || params.noErrorHint;
      if (!noErrorHint && errorInfo) {
        hintNetError(errorInfo);
      }
      // 登录状态异常
      if ([401, 403].includes(+data.code) && !!params.onLogout && !config?.customParams?.noRedirect) {
        params.onLogout(+data.code);
      }
    }
    return Promise.resolve(response);
  },
  // 拦截网络异常响应，进行提示
  onRejected(err) {
    const message = err.response?.statusText || err.message || params.errorTip;
    const status = err.response ? err.response.status : 999;
    const noErrorHint = err.config?.customParams?.noErrorHint;
    // 超时提示
    if (message?.includes('timeout')) {
      if (!noErrorHint && params.timeoutTip) {
        hintNetError(params.timeoutTip);
      }
    } else {
      // 登录状态异常
      if ([401, 403].includes(status) && !err?.config?.customParams?.noRedirect) {
        params.onLogout && params.onLogout(+status);
        const tip = params.accessErrorTip || message;
        !noErrorHint && tip && hintNetError(tip);
      } else {
        if (!noErrorHint && message) {
          hintNetError(message);
        }
      }
    }
    return Promise.reject(err);
  },
});
