import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, App, Divider } from 'antd';
import { useKeepNavigate } from '@system-global/hooks/use-keep-navigate';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import UserAgreementChecker from '@system-global/components/login-and-register-form/user-agreement-checker';
import EmailFormItem from '@system-global/components/login-and-register-form/email-form-item';
import { IApiEmailSignupReq } from '@api/system-global/__generate__/default/models';
import PwdFormItemPackage from '@system-global/components/login-and-register-form/pwd-form-item-package';
import { GoogleLoginBtn } from '@system-global/components/google-login-button';
import { FacebookLoginBtn } from '@system-global/components/facebook-login-btn';
import AppSumoCodeItem from '@system-global/components/login-and-register-form/appsumo-code-item';
import styles from './styles.module.scss';

interface ICompProps {
  registerInfo: IApiEmailSignupReq;
  onSubmit: (params: IEmailAndPwdFieldType) => void;
}

export interface IEmailAndPwdFieldType {
  email: string;
  pwd: string;
  invitationCode: string;
}

const EmailAndPwdForm: React.FC<ICompProps> = ({ onSubmit, registerInfo }) => {
  const nav = useKeepNavigate();
  const intl = useIntl();
  const { message } = App.useApp();
  const [form] = Form.useForm<IEmailAndPwdFieldType>();
  const passwordInForm = Form.useWatch('pwd', form);

  const [agreementChecked, setAgreementChecked] = useState(false);

  const onLogin = () => {
    nav(ROUTE_URLS.login);
  };

  const onNext = () => {
    form.validateFields().then(() => {
      if (!agreementChecked) {
        message.error(intl.formatMessage({ id: '请阅读并同意隐私协议' }));
        return;
      }
      const { email, pwd, invitationCode } = form.getFieldsValue();
      onSubmit({ email, pwd, invitationCode });
    });
  };

  useEffect(() => {
    form.setFieldsValue({ email: registerInfo.email, pwd: registerInfo.pwd });
  }, [form, registerInfo.email, registerInfo.pwd]);

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        <FormattedMessage id="注册" />
      </p>
      <p className={styles.tip}>
        <span className={styles.tipItem}>
          <FormattedMessage id="AppSumo码注册" />
        </span>
      </p>
      <div>
        <GoogleLoginBtn type="register" />
        <FacebookLoginBtn type="register" style={{ marginTop: 10 }} />
        <Divider>OR</Divider>
      </div>
      <Form name="passwordLogin" form={form} layout="vertical" className={styles.loginForm}>
        <EmailFormItem scene="register" onPressEnter={onNext} showResend />
        <PwdFormItemPackage value={passwordInForm} onPressEnter={onNext} />
        <AppSumoCodeItem />
      </Form>
      <Button id="registerPageSubmit" type="primary" size="large" className={styles.submitBtn} onClick={onNext}>
        <FormattedMessage id="下一步" />
      </Button>
      <UserAgreementChecker checked={agreementChecked} setChecked={setAgreementChecked} scene="register" />
      <p className={styles.subTitle}>
        <span>
          <FormattedMessage id="已经有账号了" />
        </span>
        <span className={styles.link} onClick={onLogin}>
          <FormattedMessage id="登录" />
        </span>
      </p>
    </div>
  );
};

export default EmailAndPwdForm;
