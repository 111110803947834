import { StateCreator } from 'zustand';
import { IRegisterInfo, localLoginTokenHandler, localRegisterInfoHandler } from '@system-global/utils/common';
import { logoutPost } from '@api/system-global/__generate__/default/UserBizService';
import { sendVerifyEmailPost } from '@api/system-global/__generate__/default/UserCommonService';

export interface ILoginSlice {
  // 是否为主动退出行为
  login_manualLogout: boolean;
  login_token: string;
  login_registerInfo: IRegisterInfo;
  login_setRegisterInfo: (info: IRegisterInfo) => void;
  login_doLoginSucceed(token: string): void;
  login_doLogout(local?: boolean, manually?: boolean): Promise<void>;
  login_doResendEmail(bizType: string, verifyEmail: string, emailRedirectExtra?: string): Promise<void>;
}

export const createLoginSlice: StateCreator<ILoginSlice> = (set, get) => {
  return {
    /* state */
    login_manualLogout: false,
    login_token: localLoginTokenHandler.get(),
    login_registerInfo: localRegisterInfoHandler.get() || { email: '', sendTime: 0 },
    /* action */
    login_setRegisterInfo(info) {
      set(() => ({ login_registerInfo: info }));
      localRegisterInfoHandler.set(info);
    },
    login_doLoginSucceed(token) {
      set(() => ({ login_token: token, login_manualLogout: false }));
      localLoginTokenHandler.set(token);
    },
    login_doResendEmail(bizType, verifyEmail, emailRedirectExtra) {
      return sendVerifyEmailPost({
        bizType,
        verifyEmail,
        emailRedirectExtra,
      }).then(v => {
        if (v.data.code === 0) {
          const info = { email: verifyEmail, sendTime: Date.now() };
          get().login_setRegisterInfo(info);
          return;
        }
        return Promise.reject(v.data.message);
      });
    },
    async login_doLogout(local, manually) {
      if (manually) {
        set(() => ({ login_manualLogout: true }));
      }
      try {
        if (!local) {
          await logoutPost({ noErrorHint: true });
        }
      } finally {
        localLoginTokenHandler.del();
        set(() => ({ login_token: '' }));
      }
    },
  };
};
