import { useEffect, useRef, useState } from 'react';
import { Outlet, useMatches, useSearchParams } from 'react-router-dom';
import { App } from 'antd';
import { useMemoizedFn } from 'ahooks';
import { useIntl } from 'react-intl';
import { InstallSdkModal } from '@system-global/components/install-sdk-modal';
import { useLnNavigate } from '@system-global/hooks/use-ln-navigate';
import { IRouteConfig } from '@system-global/config/types';
import { useStore } from '@system-global/store';
import { LoginStatus, VerifyStatus } from '@system-global/config/user';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { localRegisterInfoHandler } from '@system-global/utils/common';
import { IUserInfoRes } from '@system-global/store/slices/createUserSlice';
import { useIsVerified } from '@system-global/hooks/use-is-verified';
import { useRedirect } from '@system-global/hooks/use-redirect';
import { NothandlePathList, NoAutoRedirectPathList } from './path-white-list';

const EMAIL_REGISTER_TOKEN = 'token';

export function AppRoot() {
  const intl = useIntl();
  const nav = useLnNavigate();
  const { message } = App.useApp();
  const [init, setInit] = useState(false);

  const { redirectUrl, onRedirect } = useRedirect(nav);

  const isVerified = useIsVerified();
  const [searchParams, setSearchParams] = useSearchParams();
  const emailRegisterToken = searchParams.get(EMAIL_REGISTER_TOKEN);

  const matches = useMatches();
  const match = matches[matches.length - 1];
  const handle = match.handle as IRouteConfig;
  const withLogout = handle?.loginStatus === LoginStatus.withLogout;
  const withLogin = !handle || !handle.loginStatus || (handle.loginStatus as LoginStatus) === LoginStatus.withLogin;

  // 如果是主动退出行为，则 login_manualLogout 为 true
  const manualLogout = useStore(state => state.login_manualLogout);
  const isLogin = useStore(state => !!state.login_token);
  const fetchUserInfo = useStore(state => state.user_fetchUserInfo);
  const fetchBindInfo = useStore(state => state.user_fetchBindInfo);
  const doLogout = useStore(state => state.global_doLogout);
  const fetchPackageInfo = useStore(state => state.user_fetchBusinessPackage);
  const doLoginSucceed = useStore(state => state.login_doLoginSucceed);

  const isEmailVerifying = useRef(false);

  const onIsLogout = useMemoizedFn(() => {
    setInit(true);
    if (withLogin) {
      if (manualLogout || match.pathname === '/' || match.pathname.startsWith(ROUTE_URLS.sign)) {
        nav(ROUTE_URLS.sign);
      } else {
        nav(`${ROUTE_URLS.sign}?redirectUrl=${encodeURIComponent(match.pathname)}`);
      }
    }
  });

  const getBindInfo = useMemoizedFn(async () => {
    const res = await fetchBindInfo();
    const { verifyStatus: verifyStatus_1 } = res?.[0] || {};
    // 验证成功后请求套餐信息
    if (verifyStatus_1 === VerifyStatus.verified) {
      return fetchPackageInfo();
    }
  });

  const handleUserInfo = useMemoizedFn((userInfo: IUserInfoRes) => {
    const { code } = userInfo;
    if (code !== 0) {
      doLogout(true);
      return;
    }

    if (NoAutoRedirectPathList.includes(match.pathname)) {
      // google oauth handle 不自动跳转
      // nav(ROUTE_URLS.companyTracker);
      return;
    }

    if (redirectUrl) {
      onRedirect(ROUTE_URLS.default, { append: false });
      return;
    }

    if (!isVerified) {
      nav(ROUTE_URLS.installTracker);
      return;
    }

    if (isVerified && match.pathname === ROUTE_URLS.installTracker) {
      nav(ROUTE_URLS.companyTracker);
      return;
    }
    if (match.pathname === ROUTE_URLS.root || withLogout) {
      onRedirect(ROUTE_URLS.default, { append: false });
    }
  });

  const onFetchUserInfo = () =>
    fetchUserInfo().catch(err => {
      console.error('fetUserInfo error:', err);
      doLogout(true);
    });

  const getBaseInfo = useMemoizedFn(() => {
    Promise.all([onFetchUserInfo(), getBindInfo()])
      .then(([res]) => {
        if (res) {
          handleUserInfo(res);
        } else {
          return Promise.reject(new Error());
        }
      })
      .catch(e => {
        console.error('fetchBindInfo error:', e);
      })
      .finally(() => {
        setInit(true);
      });
  });

  const onEmailRegisterSucceed = useMemoizedFn((token: string) => {
    localRegisterInfoHandler.del();
    doLoginSucceed(token);

    setTimeout(() => {
      const extraParams = searchParams.get('extra');
      if (extraParams) {
        window.location.href = decodeURIComponent(extraParams);
        return;
      }
      window.location.href = `${import.meta.env.VITE_WEBSITE_URL}/register/success`;
    }, 0);

    // message.success({
    //   content: intl.formatMessage({ id: '邮箱验证成功' }),
    //   duration: 1.5,
    //   onClose() {
    //     handleUserInfo({ code: 0, data: userInfo });
    //   },
    // });
  });

  const clearEmailRegisterToken = useMemoizedFn(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(EMAIL_REGISTER_TOKEN);
    setSearchParams(newSearchParams);
    setTimeout(() => {
      isEmailVerifying.current = false;
    }, 500);
  });

  const onEmailRegister = useMemoizedFn((token: string) => {
    if (isEmailVerifying.current) {
      return;
    }
    isEmailVerifying.current = true;
    fetchUserInfo({ token, noErrorHint: true, refresh: true })
      .then(({ code, data }) => {
        if (+code === 0 && data) {
          onEmailRegisterSucceed(token);
          return;
        }
        message.error(intl.formatMessage({ id: '邮箱验证失败' }));
      })
      .catch(e => {
        message.error(e && typeof e === 'string' ? e : intl.formatMessage({ id: '邮箱验证失败' }));
      })
      .finally(() => {
        clearEmailRegisterToken();
      });
  });

  const onIsLogin = useMemoizedFn(() => {
    getBaseInfo();
  });

  useEffect(() => {
    if (NothandlePathList.includes(match.pathname)) {
      setInit(true);
      return;
    }
    if (emailRegisterToken) {
      onEmailRegister(emailRegisterToken);
      return;
    }
    if (isLogin) {
      onIsLogin();
    } else {
      onIsLogout();
    }
  }, [isLogin, onIsLogin, onIsLogout, match.pathname, emailRegisterToken, onEmailRegister]);

  if (!init) {
    return null;
  }

  return (
    <>
      <Outlet />
      <InstallSdkModal />
    </>
  );
}

export default AppRoot;
