import { useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import { countryGet } from '@api/system-global/__generate__/default/LocationService';

export function useDefaultPhoneCode(form?: FormInstance) {
  const [defaultPhoneCode, setDefaultPhoneCode] = useState<string>('');

  useEffect(() => {
    countryGet().then(res => {
      setDefaultPhoneCode(res?.data?.data?.callingCode || '');
      if (form) {
        form.setFieldsValue({ phoneCountryCode: res?.data?.data?.callingCode });
      }
    });
  }, [form]);

  return defaultPhoneCode;
}
