import { StateCreator } from 'zustand';
import {
  IApiBusinessPackageResp,
  IApiSiteResp,
  IApiUserInfoResp,
} from '@api/system-global/__generate__/default/models';
import { userInfoGet } from '@api/system-global/__generate__/default/UserBizService';
import { getPreVerifySitesGet } from '@api/system-global/__generate__/default/SiteBizService';
import { currentBusinessPackageGet } from '@api/system-global/__generate__/default/ProductService';

export interface IUserInfoRes {
  data: IApiUserInfoResp | undefined;
  code: number;
}

interface IPackageInfoRes {
  data: IApiBusinessPackageResp | undefined;
  code: number;
}

interface IFetchUserInfoProps {
  token?: string;
  noErrorHint?: boolean;
  refresh?: boolean;
  noRedirect?: boolean;
}

export interface IUserSlice {
  user_userInfo: IApiUserInfoResp | undefined;
  user_currentBindSite: IApiSiteResp | undefined;
  user_allBindSites: IApiSiteResp[];
  user_packageInfo: IApiBusinessPackageResp | undefined;
  user_setUserInfo(user_userInfo?: IApiUserInfoResp): void;
  user_setBindInfo(sites?: IApiSiteResp[]): void;
  user_setPackageInfo(user_packageInfo?: IApiBusinessPackageResp): void;
  user_fetchUserInfo(params?: IFetchUserInfoProps): Promise<IUserInfoRes>;
  user_fetchBindInfo(verifyStatus?: string, refresh?: boolean): Promise<IApiSiteResp[]>;
  user_fetchBusinessPackage(params?: IFetchUserInfoProps): Promise<IPackageInfoRes>;
}

export const createUserSlice: StateCreator<IUserSlice> = (set, get) => {
  return {
    /* state */
    user_userInfo: undefined,
    user_currentBindSite: undefined,
    user_allBindSites: [],
    user_packageInfo: undefined,
    /* action */
    user_setUserInfo(userInfo) {
      set(() => ({ user_userInfo: userInfo }));
    },
    user_setPackageInfo(packageInfo) {
      set(() => ({ user_packageInfo: packageInfo }));
    },
    user_fetchUserInfo(params) {
      const { token, noErrorHint, refresh, noRedirect } = params || {};
      const current = get().user_userInfo;
      if (!current || refresh) {
        return userInfoGet({ token, noErrorHint, noRedirect }).then(v => {
          if (v.data && +v.data.code === 0 && v.data.data) {
            get().user_setUserInfo(v.data.data);
            return { data: v.data.data, code: +v.data.code };
          }
          return { data: undefined, code: +v.data.code };
        });
      }
      return Promise.resolve({ data: current, code: 0 });
    },
    user_setBindInfo(sites) {
      if (Array.isArray(sites) && sites.length > 0) {
        set({ user_allBindSites: sites, user_currentBindSite: sites[0] });
      } else {
        set({ user_allBindSites: [], user_currentBindSite: undefined });
      }
    },
    async user_fetchBindInfo(verifyStatus, refresh) {
      const current = get().user_currentBindSite;
      if (!current || refresh) {
        const res = await getPreVerifySitesGet({ verifyStatus });
        if (res.data.data && res.data.data.sites) {
          const sites = res.data.data.sites;
          get().user_setBindInfo(sites);
          return sites;
        }
        return [];
      }
      return get().user_allBindSites;
    },
    user_fetchBusinessPackage(params) {
      const { token, noErrorHint = true, refresh } = params || {};
      const current = get().user_packageInfo;
      if (!current || refresh) {
        return currentBusinessPackageGet({ token, noErrorHint }).then(v => {
          if (v.data && +v.data.code === 0 && v.data.data) {
            get().user_setPackageInfo(v.data.data);
            return { data: v.data.data, code: +v.data.code };
          }
          return { data: undefined, code: +v.data.code };
        });
      }
      return Promise.resolve({ data: current, code: 0 });
    },
  };
};
